import React from "react"
import { graphql } from "gatsby"

// Components
import Layout from "../components/layout"
import Seo from "../components/seo"
import Banner from "../components/banner"
import Faq from "../components/faq"
import ContactDetails from "../containers/Contact/details"

const ContactPage = ({ data }) => {
  const {
    ogTitle,
    ogDescription,
    bannerTitle,
    bannerSubtitle,
    bannerImage,
    supportTitle,
    supportDetails,
    supportButtonText,
    supportLink,
    chatTitle,
    chatDetails,
    chatButtonText,
    callTitle,
    callDetails,
    callButtonText,
    callLink,
    contentTitle,
    contentImage,
    faQsTitle,
    faqsList,
  } = data.contact

  const faqData = { faQsTitle, faqsList }
  const contactData = {
    supportTitle,
    supportDetails,
    supportButtonText,
    supportLink,
    chatTitle,
    chatDetails,
    chatButtonText,
    callTitle,
    callDetails,
    callButtonText,
    callLink,
    contentTitle,
    contentImage,
  }
  return (
    <Layout>
      <Seo title={ogTitle || "Contact"} description={ogDescription} />
      <Banner title={bannerTitle} slogan={bannerSubtitle} image={bannerImage} />
      <ContactDetails data={contactData} />
      <Faq data={faqData} />
    </Layout>
  )
}

export const query = graphql`
  query ContactQuery {
    contact: contentfulContactPage {
      ogTitle
      ogDescription
      bannerTitle
      bannerSubtitle
      bannerImage {
        title
        gatsbyImageData(width: 400, quality: 100, placeholder: BLURRED)
      }
      supportTitle
      supportDetails
      supportButtonText
      supportLink
      chatTitle
      chatDetails
      chatButtonText
      callTitle
      callDetails
      callButtonText
      callLink
      contentTitle
      contentImage {
        title
        file {
          url
        }
      }
      faQsTitle
      faqsList {
        answer
        question
      }
    }
  }
`

export default ContactPage
