import React from "react"
// import { Link } from "gatsby"

import * as styles from "./index.module.scss"
import { Base, Chat } from "../../../components/icons"
import { OpenIntercom } from "../../../components/utils"

const ContactDetails = ({
  data: {
    supportTitle,
    supportDetails,
    supportButtonText,
    supportLink,
    chatTitle,
    chatDetails,
    chatButtonText,
    contentTitle,
    contentImage,
  },
}) => {
  const details = [
    {
      title: supportTitle,
      text: supportDetails,
      buttonText: supportButtonText,
      link: supportLink,
      icon: Base,
    },
    {
      title: chatTitle,
      text: chatDetails,
      buttonText: chatButtonText,
      icon: Chat,
    },
  ]

  return (
    <div>
      <div className="max-w-6xl mx-auto mt-8 xl:mt-14">
        <div className="flex flex-col lg:flex-row lg:justify-center gap-10 px-2 xl:px-0 mb-14">
          {details.map(({ icon: Icon, ...contact }, index) => {
            return (
              <div
                key={index}
                className={`${styles.featureBox} flex-1 flex flex-col items-center justify-end`}
              >
                <Icon className={styles.featureboxIcon} size={1.15} />
                <h5 className={` text-white `}>{contact.title}</h5>
                <p className="text-white text-center flex-1 xl:px-4">
                  {contact.text}
                </p>
                {contact.link ? (
                  <a
                    className={`${styles.featureButton} button-14pt`}
                    href={index === 2 ? `tel:${contact.link}` : contact.link}
                    {...(index === 0
                      ? { target: "_blank", rel: "noreferrer noopenner" }
                      : undefined)}
                  >
                    <div className="z-10">{contact.buttonText}</div>
                  </a>
                ) : (
                  <button
                    className={`${styles.featureButton} button-14pt`}
                    onClick={OpenIntercom}
                  >
                    <div className="z-10">{contact.buttonText}</div>
                  </button>
                )}
              </div>
            )
          })}
        </div>
        <h3 className="text-center">{contentTitle}</h3>
        <img
          alt={contentImage.title}
          src={contentImage.file.url}
          loading="lazy"
          className="xl:w-10/12 mx-auto my-8 xl:my-16"
        />
      </div>
    </div>
  )
}

export default ContactDetails
