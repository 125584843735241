import React from "react"

import * as styles from "./index.module.scss"

const FAQ = ({ data, whiteBG }) => {
  return (
    <div
      className={`${!whiteBG ? styles.faqContainer : ""} ${
        !whiteBG && "mt-8"
      } pt-12 pb-8 xl:pb-36 px-4 xl:px-0`}
    >
      <div className="max-w-6xl mx-auto">
        <h3 className="mb-4 lg:mb-6">{data.faQsTitle}</h3>
        <div>
          {data.faqsList.map((q, index) => (
            <div className="my-6" key={index}>
              <span className="mb-1 text-xl font-extrabold">{q.question}</span>
              <p>{q.answer}</p>
            </div>
          ))}
        </div>
      </div>
    </div>
  )
}

export default FAQ
